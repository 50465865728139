import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware(async (to) => {
    // Auth Store
    const authStore = useAuthStore()
    const { updateLoggedInStatus } = authStore

    // useRequestHeaders works only on serverside
    // Add values + assign to useState to use them also on client side
    if (import.meta.server) {
        // Get headers
        const {
            'x-content-only': webview,
            'x-correlation-id': correlationId,
            authorization
        } = useRequestHeaders()

        // Add conditional check to avoid errors
        const bearerToken = authorization?.includes('Bearer ') ? authorization?.split('Bearer ')[1] : authorization

        // Set some global states
        useState('state_bearerToken', () => bearerToken)
        useState('state_appCorrelationId', () => correlationId)
        useState('state_isWebview', () => !!webview)
        useState('state_webviewFailed', () => null)
        useState('state_userOid', () => null)
    }

    // Get data from useState
    const bearerToken = useState('state_bearerToken')
    const correlationId = useState('state_appCorrelationId')
    const webviewFailed = useState('state_webviewFailed')
    const userOid = useState('state_userOid')

    // If auth page
    if (bearerToken.value) {
        // Validate bearer token from webview to validate endpoint
        const { oid, isValid, reason } = await $fetch('/api/validate-token', {
            headers: {
                Authorization: bearerToken.value
            }
        })

        // Update login status
        updateLoggedInStatus(isValid)

        // Set some states
        correlationId.value = isValid ? correlationId.value : null
        bearerToken.value = isValid ? bearerToken.value : null
        userOid.value = isValid ? oid : null
        webviewFailed.value = isValid ? null : reason
    } else {
        webviewFailed.value = {
            name: 'No token provided',
            message: 'You need to provide a token for the webview'
        }
    }
})
